.yd-customerselection {
  display: flex;
  align-items: top;
  flex-grow: 1;
  justify-content: space-between;
}

.yd-customerselection-filter {
  width: calc(50% - 1rem);
  padding: 0.5rem 0;
}

.yd-customerselection .react-select-container {
  min-width: 175px;
  width: 70%;
  width: calc(100% - 175px);
  float: left;
}

.yd-selected-customer-list {
  padding: 0;
  margin: 0;
}

.yd-selected-customer {
  background-color:#fff;
  color:#000;
  padding: .5rem;
  display: inline-block;
  margin-bottom: .5rem;
  margin-right: .5rem;
}

.yd-selected-customer-remove {
  cursor: pointer;
}

.yd-customerselection-title {
  font-size: small;
}

.yd-customerselection-clear {
  background-color: #0080F8;
  color: #fff;
  margin-left: 0.5rem;
}

/* mobile */
@media screen and (max-width:59.99em) {
  .yd-customerselection-filter  {
    width: 100%;
  }
}