
.accordion {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: .15rem 0;
  position: relative;
}

.accordion__closed > .accordion-content {
  display: none;
}


.accordiongroup .accordion {
  margin: .15rem 0 .35rem 0;
} 

.accordion-content {
  padding: 1rem 0 0 1rem;
  width: 100%;
}

.accordion-title {
  background-color: #f6f6f6;
  font-family: 'Produkt', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 0;
  position: relative;
  width:100%;
  cursor: pointer;
}

.accordion-title--large {
  font-size: 1.25rem;
}

.accordion-title-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5rem;
  margin-left: 1.2rem;
}

.accordion-title-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.6rem;
  padding-left: 0.6rem;
  width: calc(100% - 75px);
}

.accordion-title--has-icon .accordion-title-text {
  border-left: 1px solid #ccc;
  width: calc(100% - 125px);
}

.accordion-title-extra {
  font-family: "IStok Web", Arial, sans-serif;
  font-size: smaller;
  font-weight: normal;
  display: block;
}

.accordion-title-text {
  font-family: 'Produkt',sans-serif;
}

.accordion-title--simple {
  background-color: #f6f6f6;
  font-family: 'Produkt', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem 0;
  position: relative;
  width:100%;
  cursor: pointer;
}

.accordion-title-status {
  position: absolute;
  top: 0;
  bottom: 0;
}

.accordion-title-status__warning {
  border-left: 7px solid #ffbc00;
}

.accordion-title-status__error,
.accordion-title-status__alert {
  border-left: 7px solid #e6350f;
}

.accordion-title-status__normal,
.accordion-title-status__ok {
  border-left: 7px solid #72c90c;
}

.accordion-title-status__offline {
  border-left: 7px solid #999;
}

.accordion-toggle {
  color: #0080F8;
  background-color:#fff;
  position: absolute;
  right:0.75rem;
  top: 0.6rem;
  width: 2rem;
  height: 1.55rem;
  box-shadow: 0 0.375rem 0.5rem 0 rgba(0,0,0,0.2)
}

.accordion-title--simple .accordion-toggle {
  background-color:#0080F8;
  top: 1rem;
}


.accordion-title--has-extra .accordion-toggle {
  top: 1.5rem;
}

.accordion-toggle > .svg-inline--fa {
  color: #0080F8;
  vertical-align: middle;
  width: 100%;
  font-size: 1.25rem;
  margin: 0.2rem auto;
}

.accordion-title--large .accordion-toggle > .svg-inline--fa {
  margin: 0 auto;
}

.accordion-title--simple .accordion-toggle > .svg-inline--fa {
  color: #fff;
}
