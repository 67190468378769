.yd-header {
  position: relative;
  z-index: 105;
}

.yd-header__container {
  font-family: 'Produkt', sans-serif;
  position: relative;
  height: 130px;
  border-bottom: 1px solid #ccc;
}

.yd-header__logo {
  margin-top: 25px;
  margin-left: 20px;
}

.yd-header__title {
  padding-left: 20px;
}

.yd-header__title svg {
  color: #72c90c;
}

.yd-header__menu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 1rem;
  top: 82px;
}

.yd-header__menu-toggle {
  display: none;
  position: absolute;
  top: 50px;
  right: 0.5rem;
}

.yd-header__menu-toggle .button {
  color: #333;
  font-family: "IStok Web", Arial, sans-serif;
  text-transform: uppercase;
  font-size: smaller;
  font-weight: bold;
}

.yd-header__menu .button {
  text-transform: uppercase;
  color: #666;
}

.yd-header__menu > li {
  display: inline-block;
  padding-right: 1rem;
}

.yd-header__menu-mobile {
  font-family: "Produkt", Arial, sans-serif;
  display: none;
  list-style: none;
  position: absolute;
  top: calc(130px - 32px);
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

.yd-header__menu-mobile > li {
  background: #f6f6f6;
  position: relative;
}

.yd-header__menu-mobile > li > a, .yd-header__menu-mobile > li > button {
  text-align: left;
  background: #fff;
  color: #333;
  padding: 0.75rem;
  display: inline-block;
  margin: 0 0.75rem 0.5rem 0.75rem;
  width: calc(100% - 1.5rem);
}

.yd-header__menu-mobile > li > a:first-child {
  margin-top: 0.5rem;
}

.yd-header__menu-mobile ul > li:hover {
  background-color: #f6f6f6;
}

.yd-header__menu-mobile .yd-header__collapsable {
  position: static;
  border: none;
  background: #f6f6f6;
  margin: 0 0.5rem;
}

.yd-header__selections {
  list-style: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}

.yd-header__selections li {
  display: inline-block;
  margin: 0 0.5rem;
}

.yd-header__sc-logo {
  width: 50%;
}

.yd-header__collapsable {
  position: absolute;
  margin-top: 0.5rem;
  background-color: #fff;
  border: 1px solid #999;
  padding: 1rem;
  right: 0;
}

.yd-header__profile-link > a {
  color: #0080F8;
  display: block;
  margin: 0.75rem 0;
}

.yd-header__profile-email {
  margin: 0.75rem 0;
}

.yd-header__profile-indicator {
  position: absolute;
  top: 0;
  right: 0.75rem;
  height: 3rem;
  padding: 0.75rem;
  min-width: 3rem;
  border-left: 0.25rem solid #f6f6f6;
  color: #0080F8;
}

.yd-header__selections button:disabled {
  color: #ccc;
  cursor: default;
}

/*mobile*/
@media screen and (max-width: 46.9em) {
  .yd-header__container {
    height: 130px;
  }

  .yd-header__logo {
    margin-top: 50px;
  }

  .yd-header__menu {
    display: none;
  }

  .yd-header__menu-mobile, .yd-header__menu-toggle {
    display: block;
  }

  .yd-header__selections .yd-header__selection-text {
    display: none;
  }
}

/*tablet-layout: ;*/
@media screen and (min-width: 47em) and (max-width: 59.99em) {
  .yd-header__container {
    height: 140px;
  }

  .yd-header__logo {
    margin-top: 50px;
  }

  .yd-header__selections {
    left: auto;
    top: 5px;
    right: 160px;
    font-size: .7rem;
  }
}

@media screen and (max-width: 59.99em) {
  .yd-header__grey-wedge {
    background-color: #f6f6f6;
    border-bottom: 1px solid #ccc;
    position: absolute;
    width: 100%;
    height: 38px;
  }

  .yd-header__sc-logo {
    width: 152px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 39;
    margin-top: 10px;
    margin-right: 10px;
  }

  .yd-header__selections > li {
    padding-top: .5rem;
  }

  .yd-header__logo {
    width: 135px;
  }
}

/*960px*/
@media screen and (min-width: 60em) {
  .yd-header__container {
    height: 130px;
  }

  .yd-header__sc-logo {
    width: 225px;
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 2;
  }

  .yd-header__selections {
    left: auto;
    top: 5px;
    right: 280px;
    font-size: .7rem;
  }

  .yd-header__selections > li {
    padding-top: 1.25rem;
  }

  .yd-header__title {
    padding-left: 20px;
  }

  .yd-header__logo {
    width: 185px;
  }

  .yd-header__grey-wedge {
    position: absolute;
    width: 100%;
    overflow: hidden;
    top: -20px;
    bottom: 0;
    z-index: -1;
  }

  .yd-header__grey-wedge:before {
    content: " ";
    display: block;
    position: absolute;
    background-color: #f6f6f6;
    top: 0;
    height: 100%;
    right: -1.25rem;
    width: 550px;
    transform: skewX(-12deg);
  }
}
