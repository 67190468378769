.yd-filterbar {
  position: absolute;
  top: calc(130px - 48px);
  right: 0;
  background-color: #f6f6f6;
  display: flex;
  width: 100%;
  margin: 0 auto;
  z-index: 39;
  color:#3c3c3c;
  align-items: normal;
  transition: all 0.5s ease-in-out;
  transition-property: opacity, height;
}

.yd-filterbar--visible {
  padding: 0.15rem 2rem 0.35rem 2rem;
  border-bottom: 1px solid #ccc;
  height: auto;
  min-height: 110px;
}

.yd-filterbar--hidden {
  opacity: 0;
  height: 0;
}

.yd-filterbar--hidden * {
  display: none;
}

.yd-filterbar-close {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0.5rem;
  background-color: #0080F8;
  margin: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width:47.5em) {
  .yd-filterbar--visible {
    padding: 0.15rem 4rem 0.35rem 5.5rem;
  }
}

/* mobile */
@media screen and (max-width:59.99em) {
  .yd-filterbar--visible {
    top: 38px;
    min-height: 164px;
  }
}