.message-box {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  width: 95%;
  width: calc(100% - 30px);
  max-width: 1075px;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
}

.message-box__message {
  background: #fff5aa;
  border-radius: 3px;
  border: 1px solid #ff9900;
  display: block;
  margin: 0 0 15px;
  padding: 10px 40px 10px 10px;
  position: relative;
}

.message-box__message--error {}

.message-box__message--success {
  background: #c6ffb2;
  border-color: #72c90c;
}

.message-box__message button {
  background: transparent;
  border: none;
  height: 22px;
  line-height: 22px;
  outline: none;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 8px;
  width: 22px;
}
