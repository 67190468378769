.table-two-cols {
  border-spacing: 0;
  padding: .5rem 0;
  width: 100%;
}

.table-two-cols th {
  font-weight: normal;
  text-align: left;
}

.table-two-cols tr:nth-child(even) {
  background-color: #f6f6f6
}

.table-two-cols th,
.table-two-cols td {
  padding-top: .25rem;
  padding-bottom: .25rem;
  width: 50%;
}

table.table-two-cols td:nth-child(1),
table.table-two-cols th:nth-child(1) {
  padding-right: 15px;
  padding-left: 1rem;
}

table.table-two-cols td:nth-child(2),
table.table-two-cols th:nth-child(2) {
  padding-left: 15px;
}
