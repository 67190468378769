div.react-select__control {
  box-shadow: none;
  border: 1px solid #999;
}

div.react-select__control:hover {
  border-color: #999;
}

span.react-select__indicator-separator {
  background: none;
}

div.react-select__dropdown-indicator {
  background: #0080F8;
  color: #fff;
  margin: -1px;
  padding: 10px;
}

div.react-select__dropdown-indicator:hover {
  color: #fff;
}

div.react-select-container div.react-select__dropdown-indicator svg {
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

div.react-select-container--is-open div.react-select__dropdown-indicator svg {
  transform: rotate(-180deg);
  transition: all 0.2s ease-in-out;
}

div.react-select__select-all {
  padding: 0.5rem 0 0 0.5rem;
}

span.react-select__button-divider {
  border-left: 1px solid #ccc;
  margin: 0 0.5rem 0 0.25rem;
}

.react-select-icon {
  margin-right: 0.5rem;
}