.yd-header-border {
  padding-bottom: 0.2rem;  
  border-bottom: 1px solid #ccc;
}

.yd-header-border-thin {
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #333;
}

.yd-header--main {
  font-size: 1.2rem;
  font-family: "Produkt", sans-serif;
  color:#7a7a7a;
  padding-bottom:.5rem;
  position: relative;
}

.yd-statistics-list {
  list-style: none;
  margin-left:0;
  padding-left:0;
}

.yd-statusmini {
  padding-left:5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color:#f6f6f6;
  display: block;
  margin-bottom:5px;
}


.yd-statusmini--warning {
  border-left:3px solid #ffbc00;
}

.yd-statusmini--alert {
  border-left:3px solid #e6350f;
}

.yd-statusmini--ok {
  border-left:3px solid #72c90c;
}

.yd-graph {
  clear:both;
  margin-bottom: 10px;
  text-align: center;
}

.yd-graph--header {
  font-size: 1.4rem;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.yd-graph--filter-button {
    display: inline-block;
    background-color: #f6f6f6;
    padding: .5rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    font-size: small;
    cursor: pointer;
    float: right;
}
  
.yd-graph--filter-button.selected {
  background-color: #0080F8;
  color: #fff;
}

.yd-donuts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.yd-donut {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.yd-donut svg {
  margin-right: 90px;
  margin-left: 90px;
  margin-bottom: 10px;
}

.yd-statistics-card-container {
  display: flex;
}
.yd-statistics-card-container .yd-statistics-card {
  display: flex;
  flex-basis: 50%;
  justify-content: space-around;
}
.yd-statistics-card .yd-donut {
  margin: 0;
}
.yd-statistics-card .yd-bar-chart {
  width: 100%;
}


.yd-number {
  font-size: 1.4rem;
  display: block;
  text-align: center;
  font-family: 'Istok Web', sans-serif;
} 


.yd-period-average {
  display:flex;
  flex-direction: row;
  
}

.yd-period {
  border-right:2px solid #ccc;
  flex-basis:1;
  padding-right: 1rem;
  width: 50%;
}


.yd-average {
  flex-basis:1;
  padding-left:1rem; 
  width: 50%;
}

.yd-value {
  text-align: center;
  display: block;
  margin-bottom: 10px;  
}

.yd-value-title {
  margin-bottom: 10px;
  text-align: center;
}

.yd-value-title .yd-value {
  margin: unset;
}

.yd-events-alerts {
  list-style:none;
  padding:0;
  margin-left:0;
  margin-right:0;
}

.yd-events-alert {
  padding: 10px;
  background-color: #ccc;
  margin-bottom: 10px;
  color: #fff;
  widows: 100%;
  display: block;
}

.yd-events-alert span {
  display: inline-block;
}

.yd-events-alert__ok {
  background-color:#72c90c;
}

.yd-events-alert-number {
  padding-left:15px;
}

.yd-events-alert-term {
  padding-left:20px; 
}

.yd-events-alert__alert {
  background-color:#e6350f;
}

.yd-events-alert__warning {
  background-color:#ffbc00;
}

@media screen and (min-width: 37.5em) { 
  
  .yd-events-alert {
    display: inline-block;
    width:48.077%;
    margin-right: 1.923%;
  }

}

