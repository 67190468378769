.button {
  background: #f6f6f6;
  border: none;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-right: 8px;
  min-width: 50px;
  outline: none;
  padding: 10px;
  user-select: none;
  font-family: 'Produkt'
}

button:enabled,
.button:enabled {
  cursor: pointer;
}

.button--right {
  margin-left: 8px;
  margin-right: 0;
}

.button:focus {
  
}

.button--small {
  font-size: 0.875rem;
  padding: .35rem;
}

.button--fullwidth {
  display: block;
  margin: 0;
  position: relative;
  width: 100%;
}

.button--big {
  height: 60px;
  line-height: 40px;
}

.button--fullwidth .button-icon {
  position: absolute;
  right: 1rem;
  background-color:#0080fc;
}

.button--active,
.button--white.button--active {
  background: #333;
  color: #fff;
}

.button--alert {
  border-left: 4px solid #e6350f;
}

.button--warning {border-left: 4px solid #ffbc00}

.button--success {border-left: 4px solid #72c90c}

.button--cta {
  background-color: #0053a7;
  color: #fff;
  padding: 10px;
}

.button--light {
  background-color:#0080fc;
  color:#fff;
}

.button--ghost {
  background: transparent;
  color: #0053a7;
  padding: 0;
}

.button--ghost-light {
  background: transparent;
  color: #0080F8;
  padding: 0;
}

.button--transparent {
  background: transparent;
}

/*.secondary-button--success,
.secondary-button--alert,
.secondary-button--warning,
.secondary-button--disabled,
.secondary-button--external,
.secondary-button--risk {
  padding: 10px;
}*/

.secondary-button--success,
.secondary-button--alert,
.secondary-button--warning,
.secondary-button--risk {
  color: #fff;
}

.secondary-button--alert {
  background-color: #e6350f;
}

.secondary-button--warning {
  background-color: #ffbc00;
}

.secondary-button--success {
  background-color: #72c90c;
}

.secondary-button--disabled {
  background-color: #999;
  color: #ccc;
}

.secondary-button--external {
  border: 1px solid #000;
  background-color: transparent;
}

.secondary-button--external[disabled] {
  color: #999;
}

.secondary-button--risk {
  background-color: #ff9900;
}

.button--white {
  background: #fff;
  color: #333;
}
