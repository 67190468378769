.yd-cards:nth-child(4) {
  clear: both;
}

.yd-cards__empty {
  padding-bottom: 1rem;
}

.yd-card {
  position: relative;
  background-color:#f6f6f6;
  padding:10px;
  height: 250px;
}

.yd-card svg {
  min-width: 20px;
}

.yd-card-link {
  width:31.1%;
  float:left;
  margin-right: 1.9%;
  margin-bottom: 20px;

}

.yd-card-header {
  margin-bottom: 0;
  margin-top: 0;
  color: #0080F8;
  height: 52px;
}

.yd-card-meta {
  line-height: 1.35rem;
  border-top:1px solid #ccc;
  margin-top:10px;
  color: #1a1a1a;
}

.yd-card-meta ul {
  list-style: none;
  margin-left:0;
  padding-left:0;
}

.yd-status {
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0;
}

.yd-status-message {
  display: block;
  color:#fff;
  padding-top:10px;
  margin-top:0;
  padding: 0.5rem;
}

.yd-status-loading {
  color: #1a1a1a;
}

.yd-status small {
  margin-bottom:0;
  color: #1a1a1a;
}

.yd-status--alert {
  background-color:#e6350f;
}

.yd-status--ok {
  background-color:#72c90c;
}

.yd-status--warning {
  background-color:#ffbc00;
}

.yd-status--offline {
  background-color:#999;
}

.yd-status-value {
  background-color: #fff;
  color: #1a1a1a;
  margin-top: 3px;
  padding: 0.5rem;
}

.yd-status-value--alert {
  border-left: 6px solid #e6350f;
}

.yd-status-value--ok {
  border-left: 6px solid #72c90c;
}

.yd-status-value--warning {
  border-left: 6px solid #ffbc00;
}

.yd-status-value--offline {
  border-left: 6px solid #999;
}

.yd-overview-filters {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.yd-overview-filter-label {
  display: inline-block;
  background-color: #f6f6f6;
  padding: .5rem;
  margin-right: .5rem;
  margin-bottom: .5rem;
  font-size: small;
  cursor: pointer;
}

.yd-overview-filter-label--selected {
  background-color: #0080F8;
  color: #fff;
}

.yd-overview-filter-label--alert svg {
  color: #e6350f;
}

.yd-overview-filter-label--ok svg {
  color: #72c90c;
}

.yd-overview-filter-label--warning svg {
  color: #ffbc00;
}

.yd-overview-filter-label--offline svg {
  color: #999;
}

.yd-overview-value, .yd-overview-sort {
  min-width: 255px;
}

.yd-activation--assets {
  position: absolute;
  right:0;
  top: -10px;
  background-color:transparent;
  color:#0080fc;
}

.yd-activation--assets svg {
  margin-right:5px;
}

.yd-select-customer-text {
  text-align: center;
  max-width: 425px;
  position: relative;
  font-size:1.4rem;
  padding-top:1rem;
  color:#747474;
  margin-left: auto;
  margin-right: auto;
}

.yd-select-customer-text::before {
  border-top: 3px solid #ffe170;
  display:block;
  content:" ";
  width:50px;
  height:3px;
  position: absolute;
  left:50%;
  top:0;
  margin-left: -25px;
}

/*mobile*/
@media screen and (max-width:46.9em) {
  .yd-card-link {
    width: 96%;
  }

  .yd-overview-filters {
    display: block;
  }

  .yd-overview-sort, .yd-overview-value {
    margin-bottom: 0.5rem;
  }
}

/*tablet-layout: ;*/
@media screen and (min-width:47em) and (max-width:59.99em) {
  .yd-card-link {
    width: 48%;
  }

  .yd-overview-filters {
    display: block;
  }

  .yd-overview-sort, .yd-overview-value {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    min-width: 48%;
  }
}