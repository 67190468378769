@import url("https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i");


@font-face {
  font-family: 'Produkt_orig';
  src: url("/public/assets/fonts/Produkt-Semibold-Web.eot");
  src: url("/public/assets/fonts/Produkt-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("/public/assets/fonts/Produkt-Semibold-Web.woff2") format("woff2"), url("/public/assets/fonts/Produkt-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Produkt';
  src: url("/public/assets/fonts/hinted-Produkt-Semibold.eot");
  src: url("/public/assets/fonts/hinted-Produkt-Semibold.eot?#iefix") format("embedded-opentype"), url("/public/assets/fonts/hinted-Produkt-Semibold.woff2") format("woff2"), url("/public/assets/fonts/hinted-Produkt-Semibold.woff") format("woff"), url("/public/assets/fonts/hinted-Produkt-Semibold.ttf") format("truetype"), url("/public/assets/fonts/hinted-Produkt-Semibold.svg#Produkt-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Produkt Web';
  src: url("/public/assets/fonts/Produkt-Semibold-Web.eot");
  src: url("/public/assets/fonts/Produkt-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("/public/assets/fonts/Produkt-Semibold-Web.woff2") format("woff2"), url("/public/assets/fonts/Produkt-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'iconfont';
  src: url("/public/assets/fonts/iconfont.eot?s4hiw6");
  src: url("/public/assets/fonts/iconfont.eot?s4hiw6#iefix") format("embedded-opentype"), url("/public/assets/fonts/iconfont.ttf?s4hiw6") format("truetype"), url("/public/assets/fonts/iconfont.woff?s4hiw6") format("woff"), url("/public/assets/fonts/iconfont.svg?s4hiw6#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

body {
  color: #1a1a1a;
  font-family: 'IStok Web', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.border-bottom {
  border-bottom: 1px solid #333;
  margin-bottom: 1rem;
}

.kc-subheader--border {
  border-bottom: 1px solid #000;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
