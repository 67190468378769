.back-link-wrap {
  padding: 1em;
  font-size: 16px;
}

.back-link {
  cursor: pointer;
  color: #0080F8;
  display: inline-block;
}

.back-link:hover {
  color: #0053a4;
}

.back-link svg {
  margin-right: 4px;
}
